.signin-wrapper {
    background: #52aecf;
    background: -moz-linear-gradient(0deg, #52aecf 50%, rgba(255, 255, 255, 1) 50%);
    background: -webkit-linear-gradient(0deg, #52aecf 50%, rgba(255, 255, 255, 1) 50%);
    background: linear-gradient(0deg, #52aecf 50%, rgba(255, 255, 255, 1) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#52AECF",endColorstr="#ffffff",GradientType=1);
    height: 100vh;
    @include flexb(column);
    max-width: 100%;
    min-width: 1024px;

    .signin-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include flexb(row, space-between);
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px 3%;
        min-width: 1024px;
    }

    .signin-container {
        max-width: 400px;
        width: 100%;
        padding: 30px 5%;
        @include flexb(column);
        background-color: white;
        box-shadow: 0 0 40px 20px rgba(109, 109, 109, 0.151);
        border-radius: 16px;

        img {
            margin-bottom: 20px;
        }

        .sob-btn {
            max-width: 300px;
            width: 100%;
            margin: 0;
            padding: 10px 30px;
            white-space: nowrap;
        }
    }
}
