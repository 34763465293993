.clickable-tag {
    cursor: pointer;
}

.offers-container-side {
    .offre-stopwatch {
        margin-bottom: 20px;
        .sob-v2-card-content {
            padding: 15px 0 0;
            @include flexb(row, space-between, flex-start);
            gap: 5px;

            .offer-time-card {
                h1 {
                    font-size: 35px;
                    font-weight: 600;
                    text-align: center;
                    color: #f01414;
                    margin: 0;
                }

                p {
                    font-size: 13px;
                    color: #f01414;
                    text-align: center;
                }
            }

            strong {
                margin-top: -5px;
                // margin-top: 20px;
                font-size: 35px;
                font-weight: 600;
                color: #f01414;
            }
        }
    }
}

.offer-stats-card {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .sob-v2-card-header-container {
        margin: 0;
    }

    .sob-audit-card-body {
        padding: 0;
    }

    h1 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;

        &[label="views"] {
            color: #eaa207;
        }

        &[label="clicks"] {
            color: #fc851f;
        }

        &[label="commands"] {
            color: #5fc95c;
        }
    }

    h2 {
        font-size: 13px;
        font-weight: 500;
    }

    p {
        font-size: 13px;
        margin: 0;
    }

    .sob-v2-card-content {
    }
}

.sns-detail-container .sob-v2-tabs-container .custom-tabpanel {
    background-color: transparent !important;
}
