.sob-btn {
    padding: 0.75rem 1.688rem !important;
}
.sns {
    background-color: rgb(240, 240, 240);
    zoom: 100%;
    font-family: Poppins;
    min-height: 100vh;
    scroll-behavior: smooth !important;
    padding-bottom: 2rem !important;
    position: relative;
    // Helpers
    @import "./helpers/Variables";
    @import "./helpers/Mixins";

    * {
        font-family: "Poppins";
        box-sizing: border-box;
    }

    .loader {
        min-height: 100vh;
        @include flexb;
    }

    .sob-v2-breadcrumb-container,
    .sob-v2-breadcrumb-content {
        width: auto;
    }

    .premiumText {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        height: 20px;
        margin-right: 0.5rem;
        font-size: 12px;
        padding: 0 10px;
        background: rgb(255, 245, 240);
        background: linear-gradient(159deg, rgba(255, 245, 240, 1) 0%, rgba(255, 240, 232, 1) 100%);
        color: #f48240;
    }

    .sob-isMulti {
        .sob-input-select__control {
            min-height: 55px;
            padding: 0;
        }

        .sob-select__control {
            min-height: 55px;
            padding: 0;
        }
    }

    // .sob-select .sob-select__control {
    //     padding: 0.5rem 0;
    // }
    .sob-v2-table-header-title {
        white-space: pre;
        margin-right: 0.5rem;
    }

    .sob-v2-table-header-actions {
        margin-left: 0.5rem;
    }

    .table-tag {
        // height: 45px;
        // min-height: 45px;
        @include flexb;
        // font-size: 13px;
        // font-weight: 500;
        // padding: 10px;
        width: 100px;
    }

    .tag-wide {
        width: 180px;
    }

    .tag-medium {
        width: 140px;
    }

    .small-tag {
        width: 50px;
        height: 30px;
        min-height: 30px;
        min-width: 0;
    }

    .notice-tag {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: normal;
        white-space: normal;
        margin-top: 20px;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;

        svg {
            display: block;
            margin-top: 3px;
        }
    }

    .sns-wrapper {
        padding: 0 24px 50px;

        .sns-header {
            @include flexb(row, space-between);
            min-height: 75px;

            .sns-header-btns {
                @include flexb;
                gap: 20px;

                .sob-spinner-loading {
                    margin-bottom: 10px;
                    margin-left: 20px;

                    h3 {
                        margin-bottom: 10px;
                    }
                }

                .sob-btn {
                    margin: 0;
                    margin-left: 20px;
                }
            }
        }

        .sns-container {
            width: 100%;
            margin: 0 auto;
            background-color: white;
            padding: 20px;
            border-radius: 10px;

            .sob-table-header {
                padding-top: 0;
            }

            .table {
                margin-bottom: 0;
            }

            tr {
                cursor: pointer;
            }
        }
    }

    .detail-btns {
        @include flexb;
        gap: 20px;
        flex-shrink: 0;

        a {
            text-decoration: none;
        }

        .sob-btn {
            flex-shrink: 0;
            margin: 0;
            margin-left: 20px;
        }
    }

    .sns-detail-container {
        padding: 0 24px 50px;

        .sns-detail-header {
            @include flexb(row, space-between);
            min-height: 75px;
        }

        .sns-detail-body {
            @include flexb(row, center, flex-start);
            gap: 20px;

            .sns-details-tabs {
                .tabPanel {
                    border-radius: 11px;
                    border-top-left-radius: 0;

                    .MuiBox-root {
                        border-radius: 11px;
                    }
                }
            }

            .sns-detail-content {
                // width: 80%;
                flex-basis: 70%;
                border-radius: 16px;
                flex-grow: 1;
            }

            .sns-detail-status {
                min-width: 300px;
                max-width: 400px;
                flex-grow: 1;
            }

            .sob-audit-card {
                margin: 0;
                margin-bottom: 20px;
            }
        }
    }

    .sns-create-container {
        padding: 0 24px 50px;

        .sns-header {
            @include flexb(row, space-between);
            min-height: 75px;

            .sns-header-btns {
                @include flexb;
                gap: 20px;
            }

            .sob-btn {
                margin: 0;
                margin-left: 20px;
            }
        }

        .sob-card {
            margin: 0;
        }
    }

    .pharma-container {
        width: 100%;
        margin: 0 auto;
        background-color: white;
        // padding: 4px 2%;
        border-radius: 10px;

        tr {
            cursor: pointer;
        }
    }

    section {
        // max-width: 1600px;
        margin: 0 auto;
    }

    .orders-detail-products-total-container {
        margin-top: 28px;
        margin-left: auto;
        max-width: 50%;
    }

    .orders-detail-products-total {
        // margin-top: 40px;
        padding: 0 10px;
        padding-top: 10px;
        @include flexb(row, space-between);

        .total-titles {
            h5 {
                font-weight: 400;
                font-size: 15px;
                margin: 15px 0;
            }

            h3 {
                font-weight: 700;
                font-size: 16px;
                margin: 15px 0;
            }
        }

        .total-numbers {
            h5 {
                font-weight: 600;
                font-size: 15px;
                text-align: end;
                margin: 15px 0;
            }

            h3 {
                font-weight: 700;
                font-size: 16px;
                margin: 15px 0;
                color: #00acd2;
            }
        }

        &:last-child {
            padding: 0px 10px;
            margin-top: 0;
            // background-color: #f9f9f9;
            // border-radius: 10px;
        }
    }

    .labs-offers-card {
        padding: 0;
        margin: 20px 0;
    }

    .selected-products-colRemise {
        @include flexb;
        // align-items: flex-start;
        flex-direction: column;
    }

    .selected-products-col4 {
        @include flexb;
        flex-direction: column;
    }

    .client-checkbox {
        display: block;
    }

    .shipping-table {
        height: calc(100% - 75px);
        overflow: hidden;
        .table__container {
            overflow: auto;
            height: 800px;
        }
    }

    .sob-v2-accordionCard_container {
        min-height: 56px;
    }

    .sob-v2-textarea-counter {
        position: absolute;
        top: -1.25rem;
        right: 0;
        font-size: 0.75rem;
        font-weight: 300;
        color: #9aaab9;
        color: var(--sob-gray-500);
        display: flex;
        align-items: center;
    }

    .sob-v2-tabs-container .customTab-maxWidth {
        max-width: fit-content !important;
    }

    // Basics
    @import "./basics/Default";
    @import "./basics/Buttons";
    @import "./basics/Titles";

    //****components
    @import "../components/offers/OffersListHeader";
    @import "../components/offers/OfferType";

    // Pages
    @import "./pages/Nav";
    @import "./pages/Notifications";

    @import "./pages/premiumFeats";

    @import "./pages/TableauDeBord";
    @import "./pages/Clients";
    @import "./pages/Contacts";
    @import "./pages/Fournisseurs";
    @import "./pages/BonsDeCommande";

    @import "./pages/produits/Produits";
    @import "./pages/produits/SuggererProduit";
    @import "./pages/produits/SuggestionsList";

    //ventes achats
    @import "./pages/ventesAchats/VentesAchatsCards";
    @import "./pages/ventesAchats/Offers";
    @import "./pages/ventesAchats/OffersTable";
    @import "./pages/ventesAchats/OfferCreation";
    @import "./pages/ventesAchats/OfferDetails";

    // Orders achats
    @import "./pages/ventesAchats/orders/OrderCreation";
    @import "./pages/ventesAchats/orders/OrderDetails";

    @import "./pages/logError/LogErrors";
    @import "./pages/Settings";

    @import "./pages/Pagination";
    @import "./pages/BreadCumb";
    @import "./pages/Signin";
    @import "./pages/settings/index.scss";
    @import "./pages/AddProductsToZone";

    @import "./pages/CustomPagination";
}
