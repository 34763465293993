.sy_main__wrapper {
    margin: 0 24px;

    .breadcrumb__wrapper {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;

        a {
            text-decoration: none !important;
        }
    }

    .sns__button {
        margin-right: 0px !important;
        background: #00b1d815 0% 0% no-repeat padding-box !important;
        color: #02829f !important;
        display: flex !important;
        align-items: center !important;

        img {
            margin-right: 10px !important;
        }
    }

    .last__td {
        text-align: right;
    }
}
