@mixin flexb {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-pagination-container {
    @include flexb;
    justify-content: flex-end;
    margin-bottom: 0;

    .sob-v2-TablePage__btn {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        a {
            width: 100%;
            height: 100%;
            @include flexb;
            color: var(--sob-v2-color-palette-primary);
        }
    }

    .sob-v2-TablePage__disabled {
        a {
            color: gray;
        }
    }
}
