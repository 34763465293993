.product-wrapper {
    padding: 0 3% 50px;

    .product-header {
        @include flexb(row, space-between);

        .product-header-btns {
            .sob-btn {
                margin: 0;
                margin-left: 20px;
            }
        }
    }

    .product-container {
        background-color: white;
        padding: 4px 2%;
        border-radius: 16px;

        tr {
            cursor: pointer;
        }
    }
}

.product-detail-wrapper {
    padding: 0 3% 50px;

    .product-detail-header {
        @include flexb(row, space-between);

        .sob-btn {
            margin: 0;
        }
    }

    .product-detail-container {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .product-detail-content {
            padding: 50px 0;
            background-color: white;
            width: 80%;
            border-radius: 16px;

            .sob-card {
                margin: 0;
            }
        }

        .product-detail-sidebar {
            width: 20%;

            .sob-audit-card {
                margin: 0;
            }

            .sob-btn {
                margin: 0;
                margin-bottom: 20px;
                width: 100%;
                pointer-events: none;
            }
        }
    }
}
