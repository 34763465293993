.premiumTable-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .pgrid-col {
        display: grid;
        grid-auto-rows: auto;
        // justify-content: center;
    }

    // .pgrid-col3 {
    //     position: relative;

    //     .PremiumTable-hoverBox {
    //         opacity: 0;
    //         position: absolute;
    //         background-color: transparent;
    //         height: 100%;
    //         width: 80%;
    //         left: 0;
    //         right: 0;
    //         margin: auto;
    //         border-radius: 16px;
    //         border: 0.5px solid #fb9356a9;
    //         box-shadow: 0 23px 26px #5f24010e;
    //         // background-color: white;
    //         transition: all 0.2s ease-in-out;
    //         pointer-events: none;
    //     }

    //     &:hover {
    //         .PremiumTable-hoverBox {
    //             opacity: 1;
    //         }
    //     }
    // }

    .premiumTable-header {
        @include flexb;
        justify-content: flex-start;
        flex-direction: column;
        height: 150px;
        margin: 30px 0;
    }

    .premiumTable-h3 {
        @include flexb;
        gap: 10px;
        font-size: 27px;
        font-weight: 500;
    }

    .premiumTable-h3-orange {
        @extend .premiumTable-h3;
        color: #fb9356;

        svg {
            display: block;
        }
    }

    .premiumTable-btn {
        // max-width: 301px;
        // width: 100%;
        @include flexb;
        width: 301px;
        height: 54px;
        border: none;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #fb9356;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: #ffa774;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .premiumTable-btn-outline {
        @extend .premiumTable-btn;
        color: #fb9356;
        background-color: #fff;
        border: #fb9356 1px solid;

        &:hover {
            color: #fff;
            background-color: #fb9356;
        }
    }

    .premiumTable-highlight {
        margin-top: 9px;
        border-radius: 9px;
        height: 22px;
        padding: 2px 10px;
        font-size: 12px;
        background-color: #fdf3f0;
    }

    .premiumTable-row-name,
    .premiumTable-row-answer {
        box-sizing: border-box;
        border-bottom: 1px solid lightgray;
        padding: 10px 0;
        height: 52px;
        @include flexb;
        justify-content: flex-start;
    }

    .pgrid-col3 {
        position: relative;

        .PremiumTable-hoverBox {
            opacity: 0;
            position: absolute;
            background-color: transparent;
            height: 100%;
            width: 90%;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 16px;
            border: 0.5px solid #fb9356a9;
            box-shadow: 0 23px 26px #5f24010e;
            background-color: white;
            transition: all 0.2s ease-in-out;
            z-index: 0;
            pointer-events: none;

            @media screen and (max-width: 1300px) {
                width: 100%;
            }
        }

        .premiumTable-header {
            z-index: 1;
        }

        .premiumTable-row-answer,
        .premiumTable-row-subanswer {
            // border-bottom: 1px solid rgba(211, 211, 211, 0);
            img {
                z-index: 1;
            }
        }

        &:hover {
            .PremiumTable-hoverBox {
                opacity: 1;
            }
        }
    }

    .premiumTable-row-name {
        margin: 0;
        font-size: 20px;
        font-weight: 600;

        img {
            display: block;
            margin-left: 50px;
        }
    }

    .premiumTable-row-answer {
        text-align: center;
        justify-content: center;
    }

    .premiumTable-row-subname,
    .premiumTable-row-subanswer {
        padding: 10px 0;
        height: 45px;
        @include flexb;
        justify-content: flex-start;
    }

    .premiumTable-row-subname {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        opacity: 0.73;
    }

    .premiumTable-row-subanswer {
        text-align: center;
        justify-content: center;
    }
}
