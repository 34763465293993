.suggerer-container {
    padding: 0 3%;

    .suggerer-header {
        @include flexb(row, space-between);

        .sob-btn {
            margin: 0;
        }
    }

    .sob-card {
        margin: 0;
        padding: 40px 20px;

        .sob-card-body {
            margin: 0;

            .sob-card-item-value {
                margin-bottom: 30px;
                position: relative;

                .sob-invalid-feedback {
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                }
            }
        }
    }
}
