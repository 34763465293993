.main__wrapper {
    padding: 0 24px 50px;
}
.sns .sob-card {
    padding: 20px;
}

.sns #clientForm .sob-card-body {
    background-color: transparent;
    border: none;
}
.sns .sob-form-control {
    padding: 0.5rem 22px;
}
.sns .sob-input-select__value-container,
.sns .sob-select__value-container {
    padding-left: 16px;
}

.sob-modal-header {
    margin-bottom: 34px;
    min-height: auto;
}

.SupportBtn-row {
    margin-bottom: 0 !important;
}

.dropdown-item-link {
    cursor: pointer !important;
}

/* .Th {
  white-space: nowrap;
} */

.react-datepicker__aria-live {
    display: none !important;
}

.perte_ropport_tag {
    width: fit-content !important;
}

.card__product > .sob-v2-card-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sob-v2-modal-container .sob-v2-modal .sob-v2-card {
    /* max-height: 100% !important; */
}

.sob-v2-modal-body {
    overflow: auto !important;
    height: 100%;
    /* overflow-y: visible; */
}
