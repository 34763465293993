.backward-container {
    display: flex;
    align-items: center;

    .backward-content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h3 {
            font-size: 24px;
            font-weight: 400;
            margin: 0;
        }

        h6 {
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.7);
            margin: 0;
        }
    }

    svg {
        margin-right: 8px;
        display: block;
        cursor: pointer;
        transition: all 0.15s ease-in-out;

        &:hover {
            filter: brightness(0.9);
        }
    }
}
