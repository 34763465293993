.page__404 {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .containet__404 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .annimated__404 {
            animation: zoom-in-zoom-out-404 3s ease infinite;

            @keyframes zoom-in-zoom-out-404 {
                0% {
                    transform: scale(1, 1);
                }

                50% {
                    transform: scale(1.5, 1.5);
                }

                100% {
                    transform: scale(1, 1);
                }
            }
        }

        img {
            margin: 0 12px;
        }

        .annimated__404 {
            font-size: 137px;
            color: #02657b;
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
        }

        .title {
            font-size: 20px;
            color: #372c4d;
            font-weight: 500;
            margin-bottom: 0;
            font-family: "Montserrat", sans-serif;
        }

        .subTitle {
            font-size: 20px;
            color: #372c4d;
            font-weight: 400;
            margin-bottom: 9px;
            font-family: "Montserrat", sans-serif;
        }
    }
}
