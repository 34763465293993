html {
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
    }

    &::-webkit-scrollbar-track-piece {
        background: rgba(194, 194, 194, 0.205);
    }

    &::-webkit-scrollbar-thumb {
        background: #82bac7;
        border-radius: 5px;
    }
}

* {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.contact-popup {
    .sob-table-pagination {
        .TablePage__primary {
            background-color: #f4fcfd;
            color: #02829f;
            font-size: 16;
            font-weight: 600;
        }

        .TablePage__btn {
            transition: all 0.1s ease-in-out;

            &:hover {
                filter: brightness(0.95);
            }
        }
    }
}

.plus__button {
    span {
        margin-right: 0px !important;
        display: flex !important;
        align-items: center !important;

        img {
            margin-right: 10px !important;
            width: 16px;
            height: 16px;
        }
    }
}
// .NavSubMenu__container {
//     z-index: 999 !important;
//     overflow: inherit !important;
// }

// .NavSubMenu__item-container {
//     padding-left: 0;
//     padding-right: 0;
//     cursor: pointer !important;
//     width: 100%;
//     height: 50px;
// }

// .NavSubMenu__item {
//     padding-top: 0;
//     padding-bottom: 0;
//     border-bottom: solid 1px #f0f0f0 !important;
//     height: 100%;
//     width: 100%;
// }

// .NavSubMenu__link {
//     padding: 10px;
//     font-family: Poppins !important;
//     font-size: 0.75rem !important;
//     text-align: left !important;
//     height: 100%;
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// }

// .NavSubMenu__link:hover {
//     color: var(--sob-primary) !important;
// }

// .navbar {
//     z-index: 1100 !important;
// }

// .nav-item-more-hide {
//     opacity: 0 !important;
//     z-index: -1 !important;
// }

// .nav-item-more-show {
//     opacity: 1 !important;
//     z-index: 1 !important;
// }

// .nav-item-more-sub-menu {
//     padding-left: 0 !important;
// }

// .nav-item-more-sub-menu-item {
//     display: block !important;
//     width: 100% !important;
//     height: 44px !important;
// }

// .nav-item-more-sub-menu-item .nav-link {
//     height: 44px !important;
// }

// .navbar-user-infos {
//     padding: 0.5rem;
//     width: fit-content;
// }

// .navbar-user-infos > svg {
//     margin: 0 !important;
// }

// .navbar-user-name {
//     flex-shrink: 0;
//     max-width: 100px;
//     text-align: left;
// }

// .dropdown-item-link {
//     cursor: pointer;
// }

// .navbar-more-btn {
//     .navbar-more-btn-text {
//         color: white;
//     }

//     svg {
//         color: white !important;
//     }
// }

.rug-items {
    min-height: 0 !important;
}

.rug .rug-file-input {
    display: none !important;
}

.rug .rug-items.__card {
    grid-gap: 40px !important;
}

.rug-item {
    .sob-form-control {
        display: none !important;
    }
}

// .sob-btn {
//     height: 55px;
//     padding: 15px 30px;
//     border-radius: 16px;
//     font-size: 13px;
// }

.sob-form-group {
    .sob-label {
        // margin-bottom: 0px;
    }

    .sob-form-control {
        min-height: 54px;
    }

    // margin-bottom: 20px;
    position: relative;

    .sob-invalid-feedback {
        position: absolute;
        bottom: -20px;
        left: 0;
    }
}

.disabled-input {
    .sob-input-select__control,
    .sob-select__control {
        background-color: #f7f7f7 !important;
        opacity: 0.3 !important;
        pointer-events: none;
    }
}

a {
    text-decoration: none !important;
}

.sob-form-control:disabled {
    background-color: #f7f7f7 !important;
    opacity: 0.3 !important;
}
