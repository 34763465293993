@mixin flexb($dir: row, $justify: center, $items: center) {
    display: flex;
    justify-content: $justify;
    align-items: $items;
    flex-direction: $dir;
}

.notif-card-container {
    .notif-card-content {
        @include flexb(row, flex-start, flex-start);
        gap: 12px;
        padding: 16px;
        border-bottom: 1px solid #e1e3e5;

        .notif-card-info-wrapper {
            @include flexb(column, center, flex-start);
            width: 100%;

            .notif-card-info {
                @include flexb(row, space-between);
                flex-wrap: wrap;
                width: 100%;

                .notif-card-desc {
                    font-size: 13px;
                    max-width: 380px;
                }

                .notif-card-time {
                    @include flexb;
                    gap: 6px;

                    p {
                        font-size: 10px;
                        color: #627282;
                    }
                }
            }
        }
    }

    .sob-v2-btn {
        background-color: #fff;
        border: 1px solid #dcdfe3;
        color: #232930e5;
        margin-top: 10px;

        &:hover {
            background-color: #f3f3f3;
        }
    }
}

.notif-card-container--active {
    background-color: rgba(235, 246, 252, 0.86);
}
