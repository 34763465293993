.cnx__detector {
  position: fixed;
  top: 80px;
  left: calc(50% - 250px);
  z-index: 99999;

  .offline {
    background-color: #fff3cd;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #664d03;
    z-index: 1000000;
  }

  .parts {
    display: flex;
    font-size: 13px;
    svg {
      margin-top: -3px;
      margin-right: 8px;
    }
    .ressayer {
      font-weight: 500;
      cursor: pointer;
      margin-left: 26px;
    }
  }
  .part__1 {
    padding-right: 16px;
    border-right: 1px solid #664d03;
  }
  .part__2 {
    padding-left: 16px;
  }

  .online {
    background-color: #eef9f8;
    display: flex;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #57b7ac;
    z-index: 1000000;
  }

  .online__displayer {
    display: none;
  }
}
