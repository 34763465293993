.sns__error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: auto;
    height: 95vh;
    overflow: hidden;
    width: 1440px;
    flex-wrap: wrap
}

.sns__error .image img {
    width: 100%
}

.sns__error .part__2 img {
    width: 224px;
    margin-bottom: 34px
}

.sns__error .part__2 .oops {
    color: #00B1D8;
    font-size: 60px;
    font-weight: 500;
}

.sns__error .part__2 p {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 34px
}

.sns__error .part__2 button {
    background-color: #00B1D8;
    color: white;
    font-size: 24px;
    padding: 15px 75px;
    border-radius: 16px;
    border: none
}

.sns__error .part__2 a {

    color: white;
    text-decoration: none
}

.sns__error .part__2 a:hover {

    color: white !important;
    text-decoration: none
}

.sns__error .image,
.sns__error .part__2 {
    width: 50%;
}