// ::-webkit-input-placeholder {
//     /* Edge */
//     text-transform: capitalize !important;
// }

// :-ms-input-placeholder {
//     /* Internet Explorer 10-11 */
//     text-transform: capitalize !important;
// }

// ::placeholder {
//     text-transform: capitalize !important;
// }

// .sob-select .sob-select__placeholder,
// .sob-select .sob-select__single-value {
//     text-transform: capitalize !important;
// }

a {
    text-decoration: none !important;
}
