.orders-wrapper {
    padding: 0 3% 50px;

    .orders-container {
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 4px 2%;
        border-radius: 10px;

        tr {
            cursor: pointer;
        }
    }
}

.orders-detail-wrapper {
    padding: 0 3% 50px;

    .orders-detail-header {
        @include flexb(row, space-between);
    }

    .orders-detail-container {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .orders-detail-content {
            width: 80%;
            background-color: white;
            border-radius: 16px;
        }

        .orders-detail-status {
            width: 20%;

            .orders-detail-status-text {
                background-color: white;
                padding: 20px 10px;
                border-radius: 1rem;
                @include flexb(row, space-between);
                margin-bottom: 20px;

                .h4 {
                    font-size: 1rem;
                }

                p {
                    margin: 0;
                    font-size: 0.9rem;
                    padding: 5px 10px;
                    border-radius: 10px;

                    &[label="en attente"] {
                        color: rgb(214, 183, 30);
                        background-color: rgb(255, 251, 222);
                    }

                    &[label="validée"] {
                        color: rgb(83, 181, 181);
                        background-color: rgb(235, 254, 255);
                    }

                    &[label="annulé"] {
                        color: #d11919;
                        background-color: #ffe8e8;
                    }
                }
            }

            .sob-modal-content {
                .sob-modal-header-close {
                    display: none;
                }
            }

            .sob-audit-card {
                margin: 0;
                margin-bottom: 30px;
            }
        }
    }
}
