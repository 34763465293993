.order-creation {
    .order-stopwatch {
        @include flexb(row, center, flex-start);
        gap: 5px;

        .offer-time-card {
            h1 {
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: #f01414;
                margin: 0;
            }

            p {
                font-size: 10px;
                color: #f01414;
                text-align: center;
            }
        }

        strong {
            // margin-top: -10px;
            // margin-top: 20px;
            font-size: 18px;
            font-weight: 600;
            color: #f01414;
        }
    }

    .pack-info {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;

        strong {
            font-weight: 600;
        }
    }

    .pack-error {
        @include flexb;
        background-color: #ffe7e9;
        color: #c5353e;
        height: 28px;
        font-size: 12px;
        font-weight: 500;

        svg {
            margin-right: 10px;
        }
    }

    .order-info {
        position: fixed;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 98%;
        border-radius: var(--sob-space-3, 12px);
        border: var(--sob-space-025, 1px) solid var(--Primary-Labs-primary-500, #df4751);
        background: var(--Grayscale-White, #fff);
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.2);
        padding: 16px 24px;
        @include flexb(row, space-between);
        z-index: 9999;

        .order-info-card {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            .order-info-card--content {
                @include flexb(row, flex-start);
                strong {
                    font-weight: 600;
                }

                .order-info-card--notice {
                    margin-left: 10px;
                    padding: 2px 8px;
                    border-radius: 8px;
                    background-color: #c5353e;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }

    .order-totals-container {
        .order-totals-card {
            @include flexb(row, space-between);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }

            .order-totals-value {
                font-weight: 700;
            }
        }

        .order-totals-card-special {
            @extend .order-totals-card;
            background-color: #f8fafd;
            padding: var(--sob-space-4, 16px);
            border-radius: 12px;
            font-weight: 700;
        }

        .green {
            background-color: #fff;
            border: 1px solid #25b8aa;
            color: #25b8aa;
            font-weight: 400;
        }
    }

    .order-table-container {
        .table__container {
            overflow-y: visible;
            overflow-x: visible;
        }
    }

    .order-important {
        color: #f01414;
        font-style: normal;
    }
}
