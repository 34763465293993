.log-error-wrapper {
    padding: 0 3% 50px;

    .log-error-container {
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 4px 2%;
        border-radius: 10px;

        tr {
            cursor: pointer;
        }
    }
}

.log-error-detail-container {
    padding: 0 3% 50px;
    width: 100%;

    .sob-card {
        margin: 0;
    }
}
