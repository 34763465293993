.sy_main__wrapper {
  .profil__card {
    // padding: 33px 36px !important
  }

  .profil__container {
    display: flex;
    align-items: center;
    grid-gap: 24px;

    p {
      font-size: 25px;
      margin-bottom: 0;
    }

    .email {
      display: flex;

      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }

  // historique
  .historique__card,
  .gestion__card {
    // padding: 17px 32px !important;
  }

  .is__default__tag {
    color: #00acd2;
    font-size: 13px;
    background-color: rgba(0, 177, 216, 0.08);
    border-radius: 8px;
    padding: 8px 16px;
    margin-left: 8px;
    font-weight: 500;
  }

  // gestion entropot
}
