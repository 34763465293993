.offer-creation-card {
    .discount-cards {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .discount-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        padding: 12px;
        border-radius: var(--sob-space-3, 12px);
        border: var(--sob-space-025, 1px) solid var(--Grayscale-gray-030, #dae0e7);
        opacity: var(--sob-space-025, 1);
        background: var(--Grayscale-White, #fff);
        margin-top: 20px;
        margin-right: 20px;

        p {
            font-size: 14px;
            font-weight: 600;
        }

        .discount-card_btns {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;

            button {
                margin-left: 10px;
            }
        }
    }

    .minQty-applytoall-container {
        position: relative;
        width: fit-content;

        .minQty-applytoall {
            display: flex;
            align-items: center;
            position: absolute;
            width: 290px;
            left: -300px;
            top: -40%;
            background-color: #fff;
            border-radius: 9px;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
            padding: 12px;

            p {
                margin-left: 10px;
                font-size: 14px;
            }

            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 100%;
                right: -20px;
            }
        }
    }
}
