//colors
$pColor: #19b0d2;
$fontColor: #221f1f;
$bgColor: #f8f8f8;
$hoverColor: #147e96;

//screens
$xs: 320px;
$sm: 480px;
$md: 768px;
$lg: 1024px;
$xlg: 1200px;
$xxlg: 1400px;
