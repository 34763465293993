// .btn {
//     @include flexb(row, center, center);
//     background-color: $pColor;
//     color: white;
//     font-weight: 500;
//     font-size: 0.9rem;
//     max-width: 280px;
//     width: 100%;
//     padding: 15px;
//     height: 50px;
//     border-radius: 10px;
//     border: none;
//     cursor: pointer;
//     box-shadow: 0 5px 20px -10px $pColor;
//     transition: all 0.25s ease-in-out;

//     @media screen and (max-width: $xlg) {
//         margin: 0 auto;
//     }

//     @media screen and (max-width: $sm) {
//         font-size: 0.9rem;
//     }

//     @media screen and (max-width: $xs) {
//         font-size: 0.8rem;
//     }

//     img {
//         margin-left: 10px;
//         object-fit: contain;
//         width: 15px;
//     }

//     &:focus {
//         outline: none;
//     }

//     &:hover {
//         background-color: $hoverColor;
//     }
// }

.remove-btn {
    @include flexb;
    background-color: #fff5f2;
    border: none;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    cursor: pointer;

    &:hover {
        background-color: #fae5df;
    }
}

.custom-btn {
    @include flexb(row, center, center);
    background-color: $pColor;
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    width: fit-content;
    padding: 15px 20px;
    height: 85px;
    border-radius: 6px;
    border: 1px solid white;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    @media screen and (max-width: $xlg) {
        margin: 0 auto;
    }

    @media screen and (max-width: $sm) {
        font-size: 0.9rem;
    }

    @media screen and (max-width: $xs) {
        font-size: 0.8rem;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $hoverColor;
    }
}

.sbtn {
    @include flexb;
    background-color: white;
    color: $pColor;
    font-weight: 500;
    font-size: 0.9rem;
    max-width: 200px;
    width: 100%;
    padding: 15px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid $pColor;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    @media screen and (max-width: $xlg) {
        margin: 0 auto;
    }

    @media screen and (max-width: $sm) {
        font-size: 1rem;
    }

    @media screen and (max-width: $xs) {
        font-size: 0.8rem;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $pColor;
        color: white;
    }
}

.text-btn {
    color: $pColor;
    font-weight: 500;
    font-size: 1.2rem;
    @include flexb;
    gap: 15px;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
        color: $hoverColor;
    }

    &:active,
    &:focus {
        outline: none;
    }

    img {
        width: 17px;
    }
}

.slider-btn {
    background-color: transparent;
    border: none;
    box-shadow: 0 0 20px -10px rgb(129, 129, 129);
    position: absolute;
    bottom: -50px;
    z-index: 1000;
    cursor: pointer;
    padding: 0;

    svg {
        display: block;
    }
}

.slider-btn-left {
    left: 43%;
    transform: translateX(-23px);
}

.slider-btn-right {
    right: 43%;
    transform: translateX(23px);
}

.btn-plus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    @include flexb;
    gap: 10px;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-weight: 400;

    &[label="yellow"] {
        color: #e0a43a;

        path {
            fill: #e0a43a;
        }
    }

    &[label="green"] {
        color: #03bf9c;

        path {
            fill: #03bf9c;
        }
    }

    &[label="blue"] {
        color: #45bfea;

        path {
            fill: #45bfea;
        }
    }

    &[label="orange"] {
        color: #f86d00;

        path {
            fill: #f86d00;
        }
    }

    &:hover {
        filter: brightness(0.8);
    }
}

@media screen and (max-width: $xlg) {
    .slider-btn-left {
        left: 40%;
        transform: translateX(-23px);
    }

    .slider-btn-right {
        right: 40%;
        transform: translateX(23px);
    }
}

@media screen and (max-width: $sm) {
    .slider-btn {
        svg {
            width: 40px;
            height: 38px;
        }
    }

    .slider-btn-left {
        left: 40%;
    }

    .slider-btn-right {
        right: 40%;
    }
}
