.order-details-container {
	.order-payment-container {
		margin-left: auto;
		margin-right: 30px;
		margin-bottom: 20px;
		max-width: 500px;
		width: 100%;
		font-size: 15px;

		.order-payment-row {
			@include flexb(row, space-between);
			padding: 10px 10px;
			margin: 5px 0;
			border-radius: 8px;
		}

		.payment-special-row {
			color: #079700;
			background-color: #0897000e;

			strong {
				font-weight: 700;
			}
		}

		.payment-total-row {
			background-color: #f9f9f9;
			strong {
				font-weight: 700;
			}
		}
	}
}
