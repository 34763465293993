.carPopUp-container {
    width: 600px !important;
    overflow: visible;
    border-radius: 8px;

    .sob-modal-header-close {
        z-index: 0;
    }

    .sob-modal-body {
        margin-top: 20px;

        .sob-form-control {
            height: 54px;
        }

        // .sob-row:last-of-type {
        //     margin-top: 50px;
        // }
    }

    .sob-modal-footer {
        justify-content: flex-end;

        .sob-btn {
            margin: 0;
            margin-left: 20px;
        }
    }
}
