.h1 {
    font-weight: 600;
    font-size: 2.2rem;
    margin: 0;
    margin-bottom: 10px;

    strong {
        font-weight: inherit;
        font-size: inherit;

        background: rgb(25, 176, 210);
        background: -moz-linear-gradient(
            0deg,
            rgba(25, 176, 210, 0) 10%,
            rgba(25, 176, 210, 0.24833683473389356) 10%,
            rgba(25, 176, 210, 0.26234243697478987) 50%,
            rgba(0, 190, 175, 0) 50%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(25, 176, 210, 0) 10%,
            rgba(25, 176, 210, 0.24833683473389356) 10%,
            rgba(25, 176, 210, 0.26234243697478987) 50%,
            rgba(0, 190, 175, 0) 50%
        );
        background: linear-gradient(
            0deg,
            rgba(25, 176, 210, 0) 10%,
            rgba(25, 176, 210, 0.24833683473389356) 10%,
            rgba(25, 176, 210, 0.26234243697478987) 50%,
            rgba(0, 190, 175, 0) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#19b0d2",endColorstr="#00beaf",GradientType=1);
    }
}

.h2 {
    font-size: 2.2rem;
    margin: 0;
    font-weight: 500;

    strong {
        font-weight: 700;
    }
}

.h3 {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;

    strong {
        font-size: inherit;
        font-weight: inherit;

        background: rgb(25, 176, 210);
        background: -moz-linear-gradient(
            0deg,
            rgba(25, 176, 210, 0) 10%,
            rgba(25, 176, 210, 0.24833683473389356) 10%,
            rgba(25, 176, 210, 0.26234243697478987) 50%,
            rgba(0, 190, 175, 0) 50%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(25, 176, 210, 0) 10%,
            rgba(25, 176, 210, 0.24833683473389356) 10%,
            rgba(25, 176, 210, 0.26234243697478987) 50%,
            rgba(0, 190, 175, 0) 50%
        );
        background: linear-gradient(
            0deg,
            rgba(25, 176, 210, 0) 10%,
            rgba(25, 176, 210, 0.24833683473389356) 10%,
            rgba(25, 176, 210, 0.26234243697478987) 50%,
            rgba(0, 190, 175, 0) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#19b0d2",endColorstr="#00beaf",GradientType=1);
    }
}

.h4 {
    font-weight: 500;
    font-size: 1.4rem;
    margin: 0;
}

@media screen and (max-width: $sm) {
    .h1 {
        font-size: 1.6rem;
    }

    .h3 {
        font-size: 1.3rem;
    }

    .h4 {
        font-size: 1.15rem;
    }
}
