.main__wrapper {
    .sob-btn-custom {
        min-width: 250px;

        .CustomButton__sob-icon-container {
            padding: 10px;
        }

        .CustomButton__sob-text {
            margin-bottom: 0;
            font-size: 1rem;
            max-width: inherit;
        }

        .CustomButton__sob-icon-container {
            img {
                max-width: 40px;
            }
        }

        @media screen and (max-width: 1400px) {
            padding-right: 50px;

            .CustomButton__sob-icon-container {
                img {
                    max-width: 35px;
                }
            }
        }
    }

    .premium-btn {
        @include flexb;
        width: 100%;
        height: 43px;
        border: none;
        border-radius: 9px;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        background-color: #fb9356;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 0 5px;

        @media screen and (max-width: 1355px) {
            font-size: 11px;
        }

        svg {
            display: block;
            margin-right: 5px;
        }

        &:hover {
            background-color: #ffa774;
        }
    }
}
