body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    min-width: 1100px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ReactModal__Overlay {
    z-index: 9999;
}

.react-datepicker__close-icon:after {
    background-color: #18b1d4 !important;
}

.sns .TableHeader__btn-refresh {
    margin: 0 10px !important;
}
