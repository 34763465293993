.suppliers-create-wrapper {
    .sob-v2-form-group {
        margin: 12px 0;
        position: relative;
    }

    .swap-suggest-edit {
        font-size: 12px;
        color: rgb(97, 203, 238);
        position: absolute;
        bottom: -5px;
        left: 0;
    }
}
