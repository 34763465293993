.clients-wrapper {
    padding: 0 3% 50px;

    .clients-header {
        @include flexb(row, space-between);

        .client-header-btns {
            .sob-btn {
                margin: 0;
                margin-left: 20px;
            }
        }
    }

    .clients-container {
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 4px 2%;
        border-radius: 10px;

        tr {
            cursor: pointer;
            min-height: 62px;
        }
    }
}

.client-detail-wrapper {
    padding: 0 3% 50px;

    .client-header {
        @include flexb(row, space-between);

        .client-header-btns {
            .sob-btn {
                margin: 0;
                margin-left: 20px;
            }
        }
    }

    .client-detail-container {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .client-detail-content {
            background-color: white;
            border-radius: 1rem;
            padding: 0 20px;
            width: 80%;

            .sob-card {
                margin: 0;

                .client-detail-btns {
                    margin-left: auto;
                    width: fit-content;

                    .sob-btn {
                        margin: 0;
                        margin-left: 30px;
                    }
                }
            }

            .client-detail-table {
                margin-top: 20px;
                background-color: white;
                padding: 1rem;
                border-radius: 16px;
            }
        }

        .sob-audit-card {
            width: 20%;
            margin: 0;
        }
    }
}
