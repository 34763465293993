.offers-type {
    @include flexb(row, flex-start);
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &[label='yellow'] {
        color: #e0a43a;
        border-top: 3px solid #e0a43a;
        background-color: #e0a33a0c;
    }
}
