.offers-container {
    margin: 0 auto;
    // padding: 0 3% 0;

    .offers-header {
        @include flexb(row, space-between);
        margin-top: 3px;
        min-height: 70px;
        width: 100%;
        flex-wrap: wrap;

        .offer-search {
            width: 100%;
            height: fit-content;

            .sob-table-search {
                // margin-bottom: 20px;
            }
        }
    }

    .fd-table {
        padding: 0;
    }

    .offers-list {
        margin-bottom: 50px;

        .h6 {
            font-size: 11px;
            font-weight: 400;
            text-align: left;
            color: #27b9ac;
        }

        .h3 {
            font-size: 13px;
            font-weight: 600;
            text-align: left;
        }

        .offers-inner-card {
            @include flexb(row, flex-start);
            gap: 10px;
            font-size: 12px;
            margin: 10px 0;

            .offers-inner-card-type {
                padding: 5px;
                border-radius: 10px;
                color: #27b9ac;
                background-color: #27b9ad11;
                font-size: 12px;
            }
        }

        .btn {
            height: 50px;
        }

        .sob-table-search-hide {
            overflow: visible;
        }

        .offers-cards-v {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            // margin-top: 20px;
            padding: 20px 20px;
            min-height: 80px;

            // @media screen and (min-width: 1400px) {
            //     grid-template-columns: repeat(5, 1fr);
            // }

            .sob-spinner-loading {
                .sob-spinner-loading-text {
                    margin: 0;
                }
            }

            .offers-card {
                border-radius: 16px;
                border: 1px solid #e2e2e2;
                max-width: 300px;
                box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.048);
                @include flexb(column);
                height: 100%;

                .offers-card-header {
                    flex-shrink: 0;
                    width: 100%;
                    border-radius: 16px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    cursor: pointer;

                    .lazy-load-image-background {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        border-radius: 16px;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                        display: block;
                        object-fit: cover !important;
                        min-height: 115px;
                        max-height: 115px;
                        width: 100%;
                        height: 100%;
                    }
                }

                .offers-card-body {
                    height: 100%;
                    width: 100%;
                    padding: 10px;

                    .offers-card-body-highlight {
                        @include flexb(row, space-between);
                        height: 30px;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 0.5px solid #1b273426;
                        height: 30px;

                        p {
                            font-size: 11px;
                            font-weight: 500;
                            margin: 0;
                            width: 100%;
                            text-align: center;
                            background-color: #e35757;
                            color: #fff;
                            height: 100%;
                            @include flexb;
                        }

                        em {
                            @include flexb;
                            font-size: 11px;
                            font-weight: 700;
                            font-style: normal;
                            color: #1b2734;
                            background-color: #fff;
                            width: 100%;
                            text-align: center;
                            height: 30px;
                        }

                        & > div {
                            @include flexb;
                            width: 100%;
                            height: 100%;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0em;
                            background-color: #eef2f7;
                            color: #2b4660;
                        }
                    }

                    .offers-card-body-info {
                        // padding: 10px;
                        // @include flexb(column, flex-start, flex-start);
                        display: grid;

                        .h6 {
                            margin: 0;
                            margin-top: 20px;
                            color: #25b8aa;
                            font-size: 10px;
                            font-weight: 600;
                        }

                        .h3 {
                            margin: 0;
                            margin-top: 4px;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }

                .offers-card-footer {
                    width: 100%;
                    padding: 12px;

                    .sob-v2-btn {
                        justify-content: center;
                        // transition: all 0.15s ease-in-out;
                        // justify-self: end;
                        // // margin: 0px 10px 10px;
                        width: 100%;

                        // &:hover {
                        //     background-color: #1bb9ac !important;
                        //     color: white;
                        // }
                    }
                }
            }
        }

        .carousel-root {
            .carousel {
                .slider-wrapper {
                    height: 250px;
                    @include flexb;
                }
            }
        }

        .offers-h-cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin: 0 auto;
            max-width: 90%;

            .offers-h-card {
                width: 100%;
                @include flexb;

                .offers-h-card-img {
                    border: 1px solid #e2e2e2;
                    border-radius: 1rem;
                    width: 175px;
                    height: 191px;
                    padding: 20px;
                    @include flexb;
                    flex-shrink: 0;
                    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.041);

                    img {
                        max-width: 100%;
                    }
                }

                .offers-h-card-body {
                    flex-grow: 1;
                    .offers-h-card-highlight {
                        @include flexb(row, space-between);

                        p {
                            font-size: 11px;
                            font-weight: 500;
                            margin: 0;
                            text-align: left;
                            margin-left: 20px;
                            width: 100%;
                        }

                        em {
                            font-size: 11px;
                            font-weight: 700;
                            font-style: normal;
                            padding: 10px;
                            color: #f05a29;
                            background-color: #f05b2910;
                            text-align: center;
                            width: 100%;
                        }
                    }

                    .offers-h-card-body-inner {
                        padding: 20px;

                        .offers-inner-card {
                            img {
                                max-width: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    .offers-cards-banner {
        // @include flexb(row, space-between);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        align-items: center;
        gap: 20px;
        // margin-bottom: 50px;

        .offers-card-banner {
            width: 100%;
            height: 80%;

            img {
                display: block;
                height: 100%;
                max-width: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 12px;
            }
        }
    }

    .other-offers-table {
        padding: 1rem;
    }
}

.offers-not-available-container {
    @include flexb;
    flex-direction: column;
    text-align: center;
    padding: 75px 50px;
    border: 1px dashed #25b8aa;
    border-radius: 8px;

    h3 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 8px;
    }

    p {
        font-size: 10px;
        font-weight: 400;
    }
}
