.printPopUp-container {
    max-width: 400px !important;
    width: 400px !important;
    z-index: 9999;

    .sob-modal-content {
        // max-width: 500px;

        .sob-modal-header {
            justify-content: center;
            padding: 0;
        }

        .sob-modal-body {
            justify-content: center;
            text-align: center;

            h4 {
                font-weight: 600;
                font-size: 22px;
            }
        }

        .sob-modal-footer {
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 5%;

            div {
                width: 100%;

                a {
                    width: 100%;
                }
            }

            .sob-btn {
                margin: 0;
                margin-top: 10px;
                padding: 10px 30px;
                width: 100% !important;
                height: 62px !important;
                font-size: 16px;
            }
        }
    }
}
