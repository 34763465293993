header {
    position: relative;

    .notification-wrapper {
        .notification-container {
            @include flexb;
            min-width: 75px;
            width: 75px;
            height: 100%;
            // background-color: #fff6f8;
            cursor: pointer;
            text-decoration: none;

            // &:hover {
            //     background-color: #fdedf1;
            // }

            .notif-number {
                background-color: #f22a50;
                border-radius: 50px;
                width: 32px;
                height: 18px;
                margin-top: -30px;
                @include flexb;
                color: white;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    .notification-menu-container {
        position: absolute;
        top: 60px;
        left: 200px;
        // max-width: 480px;
        width: 480px;
        padding: 1rem 0;
        z-index: 100;

        .sob-v2-card-header-container {
            padding: 0 1rem;
        }

        .sob-v2-card-content {
            max-height: 600px;
            overflow-x: hidden;

            scrollbar-width: thin;
            scrollbar-color: #82bac7 rgba(194, 194, 194, 0.205);

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }

            &::-webkit-scrollbar-track-piece {
                background: rgba(194, 194, 194, 0.205);
            }

            &::-webkit-scrollbar-thumb {
                background: #82bac7;
                border-radius: 5px;
            }
        }
    }

    .nav-container {
        width: 100%;
        background-color: white;
        box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%);

        .navbar {
            // width: 100%;
            // max-width: 1600px;
            padding: 0 3%;
            height: 65px;
            margin: 0 auto;
            box-shadow: none;
        }
    }

    .navbar-brand {
        margin-right: 0.5rem;
    }

    .HeaderItem {
        .premiumDropDown {
            display: none;
            align-items: center;
            position: absolute;
            left: 20%;
            transform: translateX(-20%);
            bottom: -50px;
            // margin: auto;
            width: 118px;
            height: 35px;
            padding: 0 10px;
            font-size: 13px;
            font-weight: 500;
            border-radius: 8px;
            background-color: #fff9f6;
            color: #f48240;
            cursor: pointer;

            &::before {
                content: "";
                height: 50px;
                width: 120px;
                position: absolute;
                top: -50px;
                left: 0;
                right: 0;
                margin: 0 auto;
                // background-color: red;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 30%;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom-color: #fff9f6;
                border-top: 0;
                margin-left: -10px;
                margin-top: -10px;
            }

            svg {
                margin-right: 6px;
            }
        }

        &:hover {
            .premiumDropDown {
                display: flex;
            }
        }
    }
}

// .sub__menu .active .NavSubMenu__link {
//     font-weight: 700 !important;
//     color: #18b1d4 !important;
// }

// .nav-item .NavSubMenu__container a.active {
//     &::after {
//         background-color: transparent !important;
//         bottom: 0;
//         content: "";
//         height: 4px;
//         left: 0;
//         position: absolute;
//         right: 0;
//         width: 100%;
//     }
// }

.nav-item-has-sub-menu .nav-link {
    padding-right: 0.438rem !important;
    cursor: pointer !important;
}
.subHeaderItem .nav-link {
    padding-right: 17px !important;
    cursor: pointer !important;
}
