.sns-zones-addproducts {
    .add-item {
        cursor: pointer;

        .add-item-img {
            width: 30px;

            &:hover {
                filter: brightness(0.7);
            }
        }

        // &:hover {
        //     .add-item-img {
        //         visibility: visible;
        //     }
        // }
    }
    .products-Header {
        font-size: 12px;
        font-weight: 500;
        padding: 8px 12px;
    }

    .accordion {
        border: none;

        .AccordionItem {
            // margin-bottom: 10px;
            .sob-v2-form-group {
                position: relative;

                .sob-v2-feedback-invalid {
                    position: absolute;
                }
            }
        }

        .AccordionItem:nth-child(even) {
            background-color: rgb(250, 250, 250);
        }

        .selected-products-col {
            font-weight: 400 !important;
        }

        .selected-products-col2 {
            font-weight: 700 !important;
        }

        .accord-border {
            margin: 10px 0;
            border-radius: 15px;
            border: 1px solid #18b1d4;
        }

        .accordion__button {
            background-color: transparent;
            padding: 8px 12px;
            font-size: 14px;

            .sob-v2-row {
                & > div {
                    display: flex;
                    align-items: center;
                }
            }

            .remove-item-img,
            .expand-btn {
                width: 30px;
            }

            .remove-item-img {
                &:hover {
                    filter: brightness(60%);
                }
            }

            &::before {
                display: none;
            }

            &:hover {
                background-color: transparent;

                .expand-btn {
                    filter: brightness(60%);
                }
            }

            &[aria-expanded="true"] {
                .expand-btn {
                    transform: rotate(180deg);
                }
            }
        }
        .accordion__panel {
            padding: 10px 20px;
            border-top: 1px solid #e4e4e4;

            .sob-v2-col {
                @include flexb(row, center, flex-end);

                .sob-v2-form-group {
                    flex-grow: 1;
                    margin: 0;
                    margin-right: 20px;
                }

                img {
                    cursor: pointer;

                    &:hover {
                        filter: brightness(0.8);
                    }
                }
            }
        }
    }
}
