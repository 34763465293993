.achats-header-inputs {
    background-color: #fbfbfb;

    .special-arrow-col {
        max-width: fit-content;
        padding-left: 0;
        @include flexb(row, center);

        img {
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                filter: brightness(0.6);
            }
        }
    }

    .sob-v2-form-group {
        margin-bottom: 0;
    }
}

.achats-advanced-search {
    margin-left: -15px;
    margin-right: -15px;
    .achats-advanced-text {
        margin-top: 10px;
        @include flexb(row, flex-end);
        color: #02829f;
        font-size: 14px;

        span {
            font-size: 20px;
            margin-right: 5px;
        }

        &:hover {
            color: #00b6df;
            cursor: pointer;
        }
    }

    .achats-advanced-inputs {
        margin-top: 10px;
        @include flexb;
        gap: 10px;

        & > div {
            flex-grow: 1;
        }
    }
}

.vente-achat-cards {
    @include flexb(row, center, flex-start);
    gap: 20px;
    margin-bottom: 100px;

    .sob-v2-card {
        margin-top: 20px;
        min-width: 350px;
    }

    & > .sob-v2-card {
        width: 35%;
        max-width: 470px;
    }

    .vente-achat-inner-cards {
        width: 70%;
        flex-grow: 1;

        .sob-v2-card:first-child {
            padding: 0;
        }

        .selected-products {
            .selected-products-header {
                border-bottom: 1px solid #efefef;
                padding: 5px 18px;
                padding-bottom: 20px;
            }

            .selected-products-row {
                @include flexb(row, space-between);
                gap: 10px;
                height: auto;

                .selected-products-col {
                    min-width: 80px;
                    font-weight: 500;
                    font-size: 15px;
                    display: flex;

                    img {
                        display: block;
                        max-width: 39px;

                        &:hover {
                            filter: brightness(60%);
                        }
                    }
                }

                .selected-products-col2 {
                    min-width: 50px;
                    width: 5%;
                }

                .selected-products-col1 {
                    @include flexb(column, center, flex-start);
                    width: 20%;
                    max-width: 50%;
                    flex-grow: 1;

                    .selected-products-warning {
                        @include flexb;
                        gap: 10px;
                        color: rgba(227, 87, 87, 1);
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;

                        svg {
                            flex-shrink: 0;
                            margin-top: -2px;
                        }
                    }
                }
            }
        }

        .accordion {
            border: none;

            .AccordionItem {
                // margin-bottom: 10px;
                .sob-v2-form-group {
                    position: relative;

                    .sob-v2-feedback-invalid {
                        position: absolute;
                    }
                }
            }

            .AccordionItem:nth-child(even) {
                background-color: rgb(250, 250, 250);
            }

            .selected-products-col {
                font-weight: 400 !important;
            }

            .selected-products-col2 {
                font-weight: 700 !important;
            }

            .accord-border {
                margin: 10px 0;
                border-radius: 15px;
                border: 1px solid #18b1d4;
                padding-bottom: 20px;
            }

            .accordion__button {
                background-color: transparent;
                padding: 8px 14px;

                &::before {
                    display: none;
                }

                &:hover {
                    background-color: transparent;

                    .expand-btn {
                        filter: brightness(60%);
                    }
                }

                &[aria-expanded="true"] {
                    .expand-btn {
                        transform: rotate(180deg);
                    }
                }
            }
            .accordion__panel {
                padding: 10px 20px;
                border-top: 1px solid #e4e4e4;

                .sob-v2-col {
                    @include flexb(row, center, flex-end);

                    .sob-v2-form-group {
                        flex-grow: 1;
                        margin: 0;
                        margin-right: 20px;
                    }

                    img {
                        cursor: pointer;

                        &:hover {
                            filter: brightness(0.8);
                        }
                    }
                }
            }
        }
    }
}
.component__addOrUpdate {
    .accordion {
        border: none;

        .AccordionItem {
            // margin-bottom: 10px;
            .sob-v2-form-group {
                position: relative;

                .sob-v2-feedback-invalid {
                    position: absolute;
                }
            }
        }

        .AccordionItem:nth-child(even) {
            background-color: rgb(250, 250, 250);
        }

        .selected-products-col {
            font-weight: 400 !important;
        }

        .selected-products-col2 {
            font-weight: 700 !important;
        }

        .accord-border {
            margin: 10px 0;
            border-radius: 15px;
            border: 1px solid #18b1d4;
            // padding-bottom: 20px;
        }

        .accord-border-error {
            margin: 10px 0;
            border-radius: 15px;
            border: 1px solid #df2121;
        }

        .accordion__button {
            background-color: transparent;
            padding: 8px 14px;

            &::before {
                display: none;
            }

            &:hover {
                background-color: transparent;
            }

            &[aria-expanded="true"] {
                .expand-btn {
                    transform: rotate(180deg);
                }
            }
        }
        .accordion__panel {
            // padding: 10px 20px;
            border-top: 1px solid #e4e4e4;

            // .sob-v2-col {
            //     display: flex;
            //     justify-content: center;
            //     align-items: flex-end;

            //     .sob-v2-form-group {
            //         flex-grow: 1;
            //         margin: 0;
            //         margin-right: 20px;
            //     }

            //     img {
            //         cursor: pointer;

            //         &:hover {
            //             filter: brightness(0.8);
            //         }
            //     }
            // }
        }
    }
}

.vente-achat-top-card {
    .top-card-header {
        width: 100%;
        background-color: #e0f6fb;
        padding: 15px 28px;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        @include flexb(row, space-between);

        .top-card-header-left,
        .top-card-header-right {
            @include flexb;
            gap: 35px;
        }

        .top-card-header-right {
            img {
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    filter: brightness(0.95);
                }
            }
        }

        h2 {
            font-size: 22px;
            font-weight: 700;
            margin: 0;
            margin-top: 5px;
        }

        h3 {
            font-size: 22px;
            font-weight: 700;
            color: #00acd2;
            margin: 0;
            margin-top: 5px;
        }
    }

    .top-card-footer {
        @include flexb(row, space-between);
        padding: 15px 28px;

        .top-card-footer-card {
            width: 100%;
            div {
                font-size: 15px;
            }

            h5 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
        }
    }
}

.ventes-productsCol {
    .sob-spinner-loading {
        min-height: 500px;
    }

    .empty-products {
        min-height: 500px;
        @include flexb(column);

        h4 {
            font-size: 18px;
        }

        img {
            width: 100px;
        }
    }

    .sob-v2-table-pagination {
        @include flexb(row, flex-end);
    }
}
