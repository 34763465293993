.pagination-btns {
    @include flexb;
    gap: 10px;
    list-style: none;

    a {
        color: gray;
    }

    .pagination-active {
        a {
            color: var(--sob-blue);
            font-weight: 500;
        }
    }

    .pagination-disabled {
        .TablePage__primary {
            background-color: lightgray;
        }
    }
}
